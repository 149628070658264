import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { deviceType } from "react-device-detect"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeatureProducts = ({ products }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  }
  return (
    <Container>
      <div className="heading heading-center">
        <h2 className="title">Sản Phẩm nổi bật</h2>
        <div className={"justify-content-center nav-border-anim mb-6"}>
          <div className="products">
            <Carousel
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={deviceType !== "mobile" ? true : false}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType={deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {products.map(({ node }) => (
                <div key={node.id} className="product product-11 text-center">
                  {node.thumbnail && (<figure className="product-media">
                      <Link to={`/product/${node.slug}`} target={"_blank"}>
                        <GatsbyImage
                          className={"product-image"}
                          alt={node.thumbnail[0].title}
                          image={getImage(node.thumbnail[0])}
                        />
                      </Link>
                    </figure>
                  )}
                  <div className="product-body">
                    <h3 className="product-title"><a href={`/product/${node.slug}`}>{node.name}</a></h3>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="more-container text-center">
        <Link to="/products" className="btn btn-outline-darker btn-more"><span>Hiển thị thêm</span><i
          className="icon-long-arrow-down" /></Link>
      </div>
    </Container>
  )
}

export default FeatureProducts