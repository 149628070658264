import * as React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TailoringForm from "../components/form"
import FeatureProducts from "../components/features"
import Categories from "../components/categories"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Index = ({ data, location }) => {
  const siteTitle = data.site?.siteMetadata?.title || `Title`
  const products = data.allContentfulProduct.edges
  const tags = data.allContentfulCategory.nodes
  const web = data.allContentfulWeb.nodes[0]

  if (products.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Trang chủ" />
        <Bio />
        <p>
          No products found.
        </p>
      </Layout>
    )
  }
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Trang chủ" />
      <div className={"intro-section bg-lighter"}>
        <div className="container-fluid p-0">
          <div className="intro-slide" style={{ backgroundImage: "url('/images/tailoring-480w.jpg')" }}>
          {/*<div className="intro-slide" style={{ backgroundImage: `url(${getImage(web.homepage[0])})` }}>*/}
            <div className="container intro-content text-center">
              <h1 className="intro-title text-white p-sm-2">
                Chúng tôi tạo ra chất lượng
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Categories categories={tags} />
      <Bio image={web.homepage[1]} description={web.homepage[1].description} />
      <FeatureProducts products={products.slice(0, 8)} />

      <div className="tailor-form container-fluid mt-2" style={{ backgroundColor: "#fafafa" }}>
        <div className="row justify-content-center p-lg-5" style={{ margin: "0 auto" }}>
          <div className="col-lg-5 col-sm-12 text-center pt-3">
            <GatsbyImage alt={"Ngo Huong image"}
                         image={getImage(web.homepage[2])} className={"w-50"}
                         style={{ margin: "0 auto" }} />
          </div>
          <div className="col-lg-7 col-sm-12 bg-light pl-md-2 pr-md-2 pl-lg-5 pr-lg-5 pb-3">
            <TailoringForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    allContentfulProduct {
      edges {
        node {
          id
          name
          slug
          thumbnail {
            gatsbyImageData(formats: NO_CHANGE)
            title
          }
        }
      }
    }
    allContentfulCategory {
      nodes {
        id
        name
        thumbnail {
          title
          gatsbyImageData(formats: NO_CHANGE)
        }
        slug
      }
    }
    allContentfulWeb {
      nodes {
        homepage {
          title
          description
          gatsbyImageData(formats: NO_CHANGE)
        }
      }
    }
  }
`
