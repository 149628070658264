import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const tags = ({ categories }) => {
  return (
    <div className="container tags pt-6" style={{ width: "1028px" }}>
      <h2 className="title-lg text-center mb-4">Danh Mục</h2>
      <div className="cate-container">
        {categories.map(({id, title, thumbnail, name, slug}) => (
          <div key={id} className="banner banner-display">
            <GatsbyImage image={getImage(thumbnail)} alt={title} />
            <div className="banner-content banner-content-center">
              <h3 className="banner-title text-white">{name}</h3>
              <Link to={`/products/?c=${slug}`} className="btn btn-outline-white banner-link">Xem chi tiết<i
                className="icon-long-arrow-right" /></Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default tags