import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const Bio = ({ image, description }) => {

   const defaultDescription = "Tại May Mặc Ngô Hường, chúng tôi chuyên sản xuất, may đo các mẫu đồng phục học sinh, đồng phục công sở. Bên cạnh đó công ty chúng tôi còn nhận thiết kế, may đo các mặt hàng thời trang và các đơn hàng số lượng lớn theo yêu cầu của khách hàng với chất lượng đảm bảo và giá thành hợp lý."

  return (
    <div className="tailor-form container-fluid mb-3 mt-5 pt-lg-5 pb-lg-5" style={{ backgroundColor: "#fafafa" }}>
      <div className="row justify-content-center">
        <div className="col-lg-5 col-sm-12">
          <GatsbyImage alt={"Ngo Huong image"} image={getImage(image)} />
        </div>
        <div className="col-lg-7 col-sm-12 bg-light mt-lg-10 pl-md-2 pr-md-2 pl-lg-5 pr-lg-5">
          <p>{description !== "" ? description : defaultDescription}</p>
        </div>
      </div>
    </div>
  )
}

export default Bio
